@import "~antd/dist/antd.css";

$dark-background: #001529;
$background: #F0F2F5;

/// Layout

#components-layout-demo-custom-trigger {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff; } }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px; } }
.site-layout {
  .site-layout-background {
    background: #fff; } }
.logo-img {
  max-height: 60px; }
.content-wrapper {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px; }
.content-wrapper-margin-less {
  margin: 0; }

.menu-float-right {
  float: right; }
.is-fullheight {
  min-height: 100vh; }
.theme-background {
  background-color: $background; }

/// IFrame

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 1rem;
  height: 0; }
.iframe-container iframe {
  position: absolute;
  top : 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px black solid; }

.dark-button {
  background-color: $dark-background; }

.is-marginless {
  margin: 0; }
.align-center {
  text-align: center; }

// Form Dynamic
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  &:hover {
    color: #777; } }
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5; }

.inline-block {
  display: inline-block; }
.cursor-pointer {
  cursor: pointer; }
.icon-button {
  font-size: 1rem; }
.float-right {
  float: right; }
